import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import How from "../components/how"

const Contact = () => (
  <Layout>
    <Seo title="Contact Us" />
    <section className="contact">
      <div className="container">
        <div className="grid center-text-hero">
          <div className="column-xs-12">
            <h4>Let's Work Together</h4>
            <h2>Are you ready to begin your next project with the professionals at Luis's Painting?</h2>
            <p>Thank you for your interest in Luis's Painting. Please feel free to contact us to request your free estimate. You can also contact us if you would like any additional information regarding our services. We will respond to your request as soon as possible. We would be happy to hear from you!</p>
          </div>
        </div>
        <div className="grid center-text-hero">
          <div className="column-xs-12 column-md-4">
            <div className="circle center" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16.001 1.072c5.291 0 9.596 4.305 9.596 9.597a9.588 9.588 0 0 1-1.29 4.799L16 29.862 7.692 15.467a9.588 9.588 0 0 1-1.289-4.798c0-5.292 4.305-9.597 9.597-9.597zm0 13.328c2.058 0 3.731-1.674 3.731-3.731s-1.674-3.731-3.731-3.731c-2.058 0-3.732 1.674-3.732 3.731s1.674 3.731 3.732 3.731zm0-14.394c-5.889 0-10.663 4.775-10.663 10.663 0 1.945.523 3.762 1.432 5.332L16 31.995l9.23-15.994a10.599 10.599 0 0 0 1.432-5.332C26.662 4.781 21.888.006 16 .006zm0 13.328a2.666 2.666 0 1 1 0-5.331 2.666 2.666 0 0 1 0 5.331z"/></svg>
            </div>
            <div className="details">
              <h3>Location</h3>
              <p>Luis's Painting</p>
            </div>
          </div>
          <div className="column-xs-12 column-md-4">
            <div className="circle center" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M23.086 1.606H8.913c-.797 0-1.444.647-1.444 1.444v25.901c0 .798.647 1.444 1.444 1.444h14.173c.797 0 1.444-.646 1.444-1.444V3.05c0-.797-.647-1.444-1.444-1.444zm.378 27.345a.377.377 0 0 1-.377.377H8.914a.377.377 0 0 1-.377-.377v-4.95h14.928v4.95zm0-6.017H8.536V5.892h14.928v17.042zm0-18.108H8.536V3.05c0-.208.169-.378.377-.378h14.173c.208 0 .377.17.377.378v1.776z"/><path d="M14.401 26.129H17.6v1.066h-3.199v-1.066z"/></svg>
            </div>
            <div className="details">
              <h3>Phone number</h3>
              <p><a href="tel:9736320560" className="page-link">(973) 632-0560</a></p>
            </div>
          </div>
          <div className="column-xs-12 column-md-4">
            <div className="circle center" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M28.244 7.47H2.672v17.06h26.656V7.47h-1.084zm-1.067 1.066L16.879 18.834c-.47.47-1.289.47-1.759 0L4.82 8.536h22.356zm-23.439.425 6.923 6.922-6.923 6.923V8.96zm.851 14.503 6.827-6.826 2.951 2.95c.436.436 1.016.677 1.633.677s1.197-.241 1.633-.677l2.951-2.951 6.826 6.826H4.588zm23.673-.657-6.923-6.924 6.923-6.924v13.848z"/></svg>
            </div>
            <div className="details">
              <h3>Email address</h3>
              <a href="mailto:luisspainting@hotmail.com" className="page-link">luisspainting@hotmail.com</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <How />
  </Layout>
)

export default Contact
