import React from "react"

const How = () => (
  <section className="how-we-work">
    <div className="container">
      <div className="grid center-text">
        <div className="column-xs-12">
          <h4>How We Work</h4>
          <h2>Our commitment to you is to perform and complete a high quality, professional job.</h2>
          <p>Whatever your next project may be, we at Luis’s Painting are ready to deliver and complete the project in a safe, timely and cost effective way.</p>
        </div>
      </div>
      <div className="grid center-text-hero">
        <div className="column-xs-12 column-md-3">
          <div className="circle center">
            <span className="number">1</span>
          </div>
          <div className="details">
            <h3>Initial Consultation</h3>
          </div>
        </div>
        <div className="column-xs-12 column-md-3">
          <div className="circle center">
            <span className="number">2</span>
          </div>
          <div className="details">
            <h3>Customized Estimate</h3>
          </div>
        </div>
        <div className="column-xs-12 column-md-3">
          <div className="circle center">
            <span className="number">3</span>
          </div>
          <div className="details">
            <h3>Project Approval &amp; Scheduling</h3>
          </div>
        </div>
        <div className="column-xs-12 column-md-3">
          <div className="circle center">
            <span className="number">4</span>
          </div>
          <div className="details">
            <h3>Job Prep, Work, &amp; Inspection</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default How
